import React from "react";
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { useRecoilValue } from "recoil";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

import { stripeOrganizations } from "recoil/publicStates";
import { userState } from "recoil/globalStates";
import { compareRole, getUserOrg } from "constants/helpers";

export default function LitterRegistry() {
  const user = useRecoilValue(userState);
  const { t } = useTranslation();
  const organizations = useRecoilValue(stripeOrganizations);
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const { registry } = watch();

  if (!compareRole(user.role, "manager") && user.organizations.length != 1) {
    throw new Error(
      "Registration currently only supports users belonging to a single organization."
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sx={{ mb: 2 }}>
        <Divider textAlign="left" sx={{ mb: 2 }}>
          <Typography variant="h2">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6} sx={{ mb: 2 }}>
        <FormControl fullWidth required>
          <InputLabel>{t("registry")}</InputLabel>
          <Controller
            name="registry"
            control={control}
            defaultValue={registry || getUserOrg(user) || ""}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Select
                {...field}
                disabled={user.role !== "admin"}
                label={t("registry")}
                value={registry || getUserOrg(user) || ""}
                renderValue={(selected) => (selected ? selected.name : "")}
                error={errors.registry && true}
              >
                {organizations.map((org) => (
                  <MenuItem key={org._id} value={org}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.registry && (
            <FormHelperText error>{t("required")}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6} sx={{ mb: 2 }}>
        {registry && (
          <Button href={registry.url} target="_blank" endIcon={<Launch />}>
            {t("registryGuidelines")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
