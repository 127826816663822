import React from "react";
import { useTranslation } from "react-i18next";

import { getKruValueLabels } from "constants/helpers";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Delete, Edit, Launch } from "@mui/icons-material";
import { kruValuesState, userState } from "recoil/globalStates";
import { constSelector, useRecoilValue } from "recoil";
import { useAuth0 } from "@auth0/auth0-react";

export default function PuppiesList({ puppies, orgAndMore = null, onDeletePuppy, formType }) {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth0();

  const user = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  const kruValues = useRecoilValue(kruValuesState);

  const dogDocType = formType === "litter" ? "dog" : "tempdog";

  return puppies.length > 0 ? (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Edit</TableCell>
            <TableCell>View</TableCell>
            <TableCell>Microchip no</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Sex</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Coat</TableCell>
            <TableCell>_id</TableCell>
            {formType === "tempLitter" && <TableCell>Remove</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {puppies.map((puppy, i) => (
            <TableRow key={i}>
              <TableCell>
                <IconButton
                  href={`/admin/${dogDocType}/${puppy._id}`}
                  target="_blank"
                >
                  <Edit />
                </IconButton>
                </TableCell>
              <TableCell>
                {orgAndMore && (
                  <IconButton
                    href={`/${orgAndMore.organization.prefix}/dog/${puppy._id}`}
                    target="_blank"
                  >
                    <Launch />
                  </IconButton> )}
              </TableCell>
              <TableCell>{puppy.microchipNumber}</TableCell>
              <TableCell>{puppy.name}</TableCell>
              <TableCell>{t(puppy.sex)}</TableCell>
              <TableCell>
                {puppy.color && getKruValueLabels(puppy.color, "en", kruValues)}
              </TableCell>
              <TableCell>
                {puppy.coat && getKruValueLabels(puppy.coat, "en", kruValues)}
              </TableCell>
              <TableCell>{puppy._id}</TableCell>
              {formType === "tempLitter" || user?.role === "admin" ? (
                <TableCell>
                  <IconButton onClick={() => onDeletePuppy(i)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <Typography variant="body">No puppies</Typography>
  );
}
