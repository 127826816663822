import { atom, atomFamily, selectorFamily } from "recoil";
import { getLitterById } from "api/privateRoutes";
import { accessTokenState } from "./globalStates";

const sessionStorageEffect = key => ({ setSelf, onSet }) => {
  const savedValue = sessionStorage.getItem(key);
  if (savedValue != null) {
    setSelf(JSON.parse(savedValue));
  }

  onSet(newValue => {
    sessionStorage.setItem(key, JSON.stringify(newValue));
  });
};

export const activeStepState = atom({
  key: "activeStepState",
  default: 0,
});

export const dogRegistrationState = atom({
  key: 'dogRegistrationState',
  default: {
    activeStep: 0,
    registry: null,
    owner: null,
    sire: null,
    dam: null,
    name: '',
    embarkURL: '',
    microchipNumber: '',
    sex: 'male',
    dateOfBirth: null,
    countryOfOrigin: null,
    declaredBreed: '',
    declaredMix: '',
    declaredType1: '',
    declaredType2: '',
    height: '',
    weight: '',
    heightIn: '',
    weightLb: '',
    dataVerified: false,
    orgAgreement: false,
    platformAgreement: false,
    attachments: {},
    filePaths: {
      pedigreeCert: null,
      profileImage: null,
      misc: null,
    },
    additionalInfo: '',
  },
  effects_UNSTABLE: [sessionStorageEffect('dogRegistrationState')],
});

export const litterFormState = atom({
  key: "litterFormState",
  default: null,
});

// DELETEME: do we need this or can we just use existingLitterState?
export const litterObjectState = atomFamily({
  key: "litterObjectState",
  default: (id) => existingLitterState(id),
});

export const existingLitterState = selectorFamily({
  key: "existingLitterState",
  get:
    (id) =>
    async ({ get }) => {
      const accessToken = get(accessTokenState);
      const response = await getLitterById(id, { accessToken });
      if (response?.error) {
        throw response;
      }
      return response;
    },
});

export const existingDogState = selectorFamily({
  key: "existingDogState",
  get:
    (id) =>
    async ({ get }) => {
      const accessToken = get(accessTokenState);
      const response = await getDogById(id, { accessToken });
      if (response?.error) {
        throw response;
      }
      return response;
    },
});

export const statsState = atom({
  key: "statsState",
  default: {
    mating: "",
    birth: "",
    deadPuppies: "",
  },
});

export const attachmentsState = atom({
  key: "attachmentsState",
  default: {
    sireCertificate: [],
    damCertificate: [],
    microchipCert: [],
    misc: [],
  },
});

export const filepathsState = atom({
  key: "filepathsState",
  default: {
    sireCertificate: null,
    damCertificate: null,
    microchipCert: null,
    misc: null,
  },
});

export const checkboxesState = atom({
  key: "checkboxesState",
  default: {
    breederVerified: false, // At form page
    dataVerified: false, // At summary page
    eulaAgreement: false, // At summary page
  },
});

export const formErrorsState = atom({
  key: "formErrorsState",
  default: {},
});

export const userIsSireOwnerState = atom({
  key: "userIsSireOwnerState",
  default: null,
});

export const userIsDamOwnerState = atom({
  key: "userIsDamOwnerState",
  default: null,
});
