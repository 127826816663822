import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

// Recoil
import { useRecoilValue, useResetRecoilState } from "recoil";
import { genericDogByIdState } from "recoil/publicStates";
import { kruValuesState } from "recoil/globalStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";

// COMPONENTS
import { displayDeclaredTypes, getCountryByCode, getKruValueLabel } from "constants/helpers";

export default function DogPaymentSuccess() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const dog = useRecoilValue(genericDogByIdState(id));
  const countryOfOrigin = getCountryByCode(dog?.countryOfOrigin, language);
  const resetFormData = useResetRecoilState(dogRegistrationState);
  resetFormData();


  // FIXME support multiple registering organizations
  return (
    <>
      <Paper sx={{ p: 4 }}>
        <Grid container alignItems="center" spacing={6}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h2" sx={{ mb: 2 }}>
            {dog.pendingRegistration ?
              t("Dog registration submitted - pending approval") :
              <>
              {t("Dog registration successful")}
              <br />
              {dog.regNumber} / {dog.organizations[0].regNumber}
              </>
            }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box display="flex" justifyContent="center">
              <CheckCircleOutlined
                color="primary"
                sx={{
                  fontSize: { xs: "80px", sm: "100px" },
                  textAlign: "center",
                }}
              />
            </Box>
          </Grid>
          {dog && (
            <Grid item xs={12}>
              <Typography variant="h3">{t("summary")}</Typography>
              <TableContainer>
                <Table sx={{ minWidth: 200 }}>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">{t("registry")}</TableCell>
                      <TableCell>{dog.organizations[0]?.organization?.name || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("dogsName")}</TableCell>
                      <TableCell>{dog.name || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        {t("microchipNumber")}
                      </TableCell>
                      <TableCell>{dog.microchipNumber || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("dob")}</TableCell>
                      <TableCell>
                        {dayjs(dog.dateOfBirth).format("MM.DD.YYYY") || ""}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("sex")}</TableCell>
                      <TableCell>{t(dog.sex) || ""}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("Breed or mix")}</TableCell>
                      <TableCell>
                        {displayDeclaredTypes(dog, kruValues, language)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">
                        {t("countryOfOrigin")}
                      </TableCell>
                      <TableCell>{countryOfOrigin}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("coat")}</TableCell>
                      <TableCell>
                        {dog.coat?.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.coat.length - 1 === i ? "" : ", ")
                          );
                        }) || " "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("color")}</TableCell>
                      <TableCell>
                        {dog.color?.map((value, i) => {
                          return (
                            getKruValueLabel(value, language) +
                            (dog.color.length - 1 === i ? "" : ", ")
                          );
                        }) || " "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("height")}</TableCell>
                      <TableCell>
                        {dog.height || "-"}
                        {" cm"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">{t("weight")}</TableCell>
                      <TableCell>
                        {dog.weight || "-"}
                        {" kg"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
}
