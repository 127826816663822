import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Divider,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { Controller, useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";
import { attachmentsState, filepathsState } from "recoil/registrationStates";

export default function LitterAttachments() {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const { control, watch, setValue } = useFormContext();

  const { verificationNeeded } = watch();

  const [attachments, setAttachments] = useRecoilState(attachmentsState);
  const [filePaths, setFilePaths] = useRecoilState(filepathsState);
  const puppies = watch("puppies");

  const attachmentGridRowProps = {
    spacing: 2,
    flexWrap: lessThanSmall ? "wrap" : "nowrap",
    justifyContent: "space-between",
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    // Create FormData objects ready to send
    let newAttachments = [];
    for (const file of files) {
      const formData = new FormData();
      if (name.startsWith("images-")) {
        formData.set("index", name.substring(7));
        formData.set("category", "profileImage");
      } else {
        formData.set(`category`, name);
      }
      formData.set(`attachment`, file);
      newAttachments.push(formData);
    }
    setAttachments({ ...attachments, [name]: newAttachments });
    setValue("attachments", { ...attachments, [name]: newAttachments });

    // Handle file name display
    const path = !files.length
      ? t("noFileSelected")
      : files.length === 1
      ? `${files[0].name}`
      : `${files.length} ${t("filesSelected")}`;

    setFilePaths({ ...filePaths, [name]: path });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
          <Typography variant="body">
          {t("Upload the attachments requested for registration. Attachments with health information go under \"Other attachments.\"")}
            <br />
            {t("You may upload a single image of each puppy, which will be used for their profile picture.")}
            <br />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mb: 2 }}>
          {puppies && puppies.map((puppy, index) => (
        <Grid key={`images-${index}`}
              container
              spacing={attachmentGridRowProps.spacing}
              flexWrap={attachmentGridRowProps.flexWrap}
              justifyContent={attachmentGridRowProps.justifyContent}
            >
              <Grid item xs={12} md={7}>
                {`${puppy.name} ${t("profile photo")}`}
              </Grid>
              <Grid item xs={12} md={4}>
                {filePaths[`images-${index}`] || t("noFileSelected")}
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    hidden
                    accept="image/*"
                    name={`images-${index}`}
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>
          ))}

          {verificationNeeded?.sire === "attachment" && (
            <Grid
              container
              spacing={attachmentGridRowProps.spacing}
              flexWrap={attachmentGridRowProps.flexWrap}
              justifyContent={attachmentGridRowProps.justifyContent}
            >
              <Grid item xs={12} md={7}>
                {`${t("siresMatingCertificate")} *`}
              </Grid>
              <Grid item xs={12} md={4}>
                {filePaths.sireCertificate || t("noFileSelected")}
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    hidden
                    multiple
                    accept="image/*,.pdf"
                    name="sireCertificate"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>
          )}
          {verificationNeeded?.dam === "attachment" && (
            <Grid
              container
              spacing={attachmentGridRowProps.spacing}
              flexWrap={attachmentGridRowProps.flexWrap}
              justifyContent={attachmentGridRowProps.justifyContent}
            >
              <Grid item xs={12} md={7}>
                {`${t("damsMatingCertificate")} *`}
              </Grid>
              <Grid item xs={12} md={4}>
                {filePaths.damCertificate || t("noFileSelected")}
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="contained" component="label">
                  {t("selectFile")}
                  <input
                    hidden
                    multiple
                    accept="image/*,.pdf"
                    name="damCertificate"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
            </Grid>
          )}

          {/* <Grid
            container
            spacing={attachmentGridRowProps.spacing}
            flexWrap={attachmentGridRowProps.flexWrap}
            justifyContent={attachmentGridRowProps.justifyContent}
          >
            <Grid item xs={12} md={7}>
              {`${t("puppiesMicrochipCertificates")} *`}
            </Grid>
            <Grid item xs={12} md={4}>
              {filePaths.microchipCert || t("noFilesSelected")}
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" component="label">
                {t("selectFiles")}
                <input
                  hidden
                  multiple
                  accept="image/*,.pdf"
                  name="microchipCert"
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            </Grid> */}

          <Grid
            container
            spacing={attachmentGridRowProps.spacing}
            flexWrap={attachmentGridRowProps.flexWrap}
            justifyContent={attachmentGridRowProps.justifyContent}
          >
            <Grid item xs={12} md={7}>
              {`${t("attachments")}`}
            </Grid>
            <Grid item xs={12} md={4}>
              {filePaths.misc || t("noFileSelected")}
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" component="label">
                {t("selectFile")}
                <input
                  hidden
                  multiple
                  accept="image/*,.pdf"
                  name="misc"
                  type="file"
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Notes")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("In the \"Notes\" field, include any additional information for the registrar, including any URLs for health information.")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
          name="additionalInfo"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Notes"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
          )}
        />
        </Grid>

      </Grid>
    </>
  );
}
