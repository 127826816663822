import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Upload } from "@mui/icons-material";

import { useFormContext } from "react-hook-form";
import { useRecoilState } from "recoil";
import {
  attachmentsState,
  filepathsState,
} from "recoil/registrationStates";

export default function TreedogAttachments() {
  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();

  const { verificationNeeded } = watch();

  const [attachments, setAttachments] = useRecoilState(attachmentsState);
  const [filePaths, setFilePaths] = useRecoilState(filepathsState);

  const attachmentGridRowProps = {
    spacing: 2,
    flexWrap: lessThanSmall ? "wrap" : "nowrap",
    justifyContent: "space-between", // HERE This is where to fix the weird alignment
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    // Create FormData objects ready to send
    let newAttachments = [];
    for (const file of files) {
      const formData = new FormData();
      formData.set(`attachment`, file);
      formData.set(`category`, name);
      newAttachments.push(formData);
    }
    setAttachments({ ...attachments, [name]: newAttachments });
    setValue("attachments", { ...attachments, [name]: newAttachments });

    // Handle file name display
    const path = !files.length
      ? t("noFileSelected")
      : files.length === 1
      ? `${files[0].name}`
      : `${files.length} ${t("filesSelected")}`;

    setFilePaths({ ...filePaths, [name]: path });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("attachments")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("attachmentsInfoText")}
            <br />
            <br />
            {t("attachmentsInfoText2")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <List disablePadding>
            { /* <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="microchipCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="microchipCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={`${t("treedogsMicrochipCertificate")} *`}
                secondary={filePaths.microchipCert || t("noFileSelected")}
              ></ListItemText>
            </ListItem> */ }
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="pedigreeCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      accept="image/*,.pdf"
                      name="pedigreeCert"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t("Dog's pedigree")}
                secondary={filePaths.pedigreeCert || t("noFileSelected")}
              ></ListItemText>
            </ListItem>
            <ListItem
              disablePadding
              secondaryAction={
                lessThanSmall ? (
                  <Fab color="primary" size="small" component="label">
                    <Upload />
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Fab>
                ) : (
                  <Button variant="contained" component="label">
                    {t("uploadFile")}
                    <input
                      hidden
                      multiple
                      accept="image/*,.pdf"
                      name="misc"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </Button>
                )
              }
            >
              <ListItemText
                primary={t("otherAttachments")}
                secondary={filePaths.misc || t("noFilesSelected")}
              ></ListItemText>
            </ListItem>
          </List>
    </Grid>
    </Grid>
    </>
  );
  }

