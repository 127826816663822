import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";

// Recoil
import {
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";

// MUI
import {
  Divider,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

function AdminDogAppearance({ dog, formMethods }) {
  const [initialDog, setInitialDog] = useState(dog);
  const { t, i18n } = useTranslation();
  const kruValues = useRecoilValue(kruValuesState);
  const { clearErrors, control, setValue, watch, formState: { errors } } = formMethods;
  const formdata = watch();

  return (
    <>
<Grid item xs={12}>
<Divider textAlign="left" sx={{ my: 2 }}>
  <Typography variant="h5">{t("Size")}</Typography>
</Divider>
</Grid>

<Grid container spacing={2}>
    <Grid item xs={12} sm={3}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="heightIn"
                control={control}
                defaultValue=""
                rules={{
                  min: {
                    value: 1,
                    message: t("Height must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Height - in")}
                    error={errors?.heightIn && true}
                    helperText={errors && errors?.heightIn?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('height', Math.round(e.target.value * 2.54));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="height"
                control={control}
                defaultValue=""
                rules={{
                  min: {
                    value: 1,
                    message: t("Height must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Height - cm")}
                    error={errors?.height && true}
                    helperText={errors && errors?.height?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('heightIn', Math.round(e.target.value / 2.54));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid item xs={12} sm={3}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="weightLb"
                control={control}
                defaultValue=""
                rules={{
                  min: {
                    value: 1,
                    message: t("Weight must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Weight - lb")}
                    error={errors?.weightLb && true}
                    helperText={errors && errors?.weightLb?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('weight', Math.round(e.target.value * 0.453592));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="weight"
                control={control}
                defaultValue=""
                rules={{
                  min: {
                    value: 1,
                    message: t("Weight must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Weight - kg")}
                    error={errors?.weight && true}
                    helperText={errors && errors?.weight?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('weightLb', Math.round(e.target.value / 0.453592));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>


{/* HIDDEN until schema update
<Grid item xs={12} md={6}>
<FormControl fullWidth>
  <InputLabel>Color</InputLabel>
  <Controller
    name="color"
    control={control}
    defaultValue={initialDog?.color || []}
    render={({ field: { ref, name, ...field } }) => (
      <Select {...field} fullWidth multiple label="Color">
        <ListSubheader>Colors (select one)</ListSubheader>
        {kruValues
          .filter((i) => i.category === "coat.colors")
          .map((color) => (
            <MenuItem key={color._id} value={color._id}>
              {color.en.name}
            </MenuItem>
          ))}
        <ListSubheader>Markings (select multiple)</ListSubheader>
        {kruValues
          .filter((i) => i.category === "coat.markings")
          .map((color) => (
            <MenuItem key={color._id} value={color._id}>
              {color.en.name}
            </MenuItem>
          ))}
      </Select>
    )}
  />
</FormControl>
</Grid>
<Grid item xs={12} md={6}>
<FormControl fullWidth>
  <InputLabel>Coat</InputLabel>
  <Controller
    name="coat"
    control={control}
    defaultValue={initialDog?.coat || []}
    render={({ field: { ref, name, ...field } }) => (
      <Select {...field} fullWidth multiple label="Coat">
        <ListSubheader>Coat types (select one)</ListSubheader>
        {kruValues
          .filter((i) => i.category === "coat.types")
          .map((color) => (
            <MenuItem key={color._id} value={color._id}>
              {color.en.name}
            </MenuItem>
          ))}
        <ListSubheader>
          Coat qualities (select multiple)
        </ListSubheader>
        {kruValues
          .filter((i) => i.category === "coat.qualities")
          .map((color) => (
            <MenuItem key={color._id} value={color._id}>
              {color.en.name}
            </MenuItem>
          ))}
      </Select>
    )}
  />
</FormControl>
</Grid> */ }

    </>  );
}

export default AdminDogAppearance;
