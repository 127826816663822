import { createNewTreedog, registerDog } from "api/adminRoutes";
import {
  addAttachment,
  createCheckout,
  createDog,
  createLitter,
  createPendingLitter,
  createStatistic,
  updatePrimaryImageById
} from "api/privateRoutes";

import {
  compareRole,
  getPaymentAmount,
  getUserManagerPlusOrg,
} from "constants/helpers";

/* DELETEME */
const getImageFileName = (attachment) => {
  let file = attachment.get("attachment");
  const name = attachment.get("name");
  let suffix = Date.now() + `-` + Math.round(Math.random() * 1e9);
  const nameParts = name.split('.');
  const baseName = nameParts.slice(0, -1).join('.');
  const extension = nameParts.pop();
  return `${baseName}-${suffix}.${extension}`;
};

const getRegAdmin = (user, registry) => {
  return compareRole(user.role, "manager") ||
    getUserManagerPlusOrg(user, registry);
};

const getOwner = (user, dog) => {
  let owner = user._id;
  if (getRegAdmin(user, dog.registry)) {
    owner = dog.owner?._id;
  }
  return owner;
};

export const sendLitterRegDataToApi = async (litter, user, accessToken) => {
  try {
    // if this user is allowed to set breeder, accept from form
    let breeder = user._id;

    if (getRegAdmin(user, litter.registry)) {
      breeder = litter.breeder?._id;
    }

    const puppyIds = [];

    const organizationWithReg = {
      organization: litter.registry._id,
      regNumber: null,
      regClass: `reg.classes.limited`,
      regType: `reg.types.litter`,
    };

    // Map puppies into correct form
    const mappedPuppies = litter.puppies.map((puppy) => {
      /* UNCOMMENT when coat stuff returns 
      puppy.markings.unshift(puppy.color);
      puppy.coatqualities.unshift(puppy.coattype); */
      return {
        name: puppy.name,
        owners: [breeder],
        microchipNumber: puppy.microchipNumber,
        sex: puppy.sex,
        /* color: puppy.markings,
        coat: puppy.coatqualities, */
        regType: "reg.types.litter",
        regClass: "reg.classes.limited",
        organizations: [organizationWithReg],
        countryOfOrigin: litter.countryOfOrigin.code,
        breeder: breeder,
        sire: litter.sire._id,
        dam: litter.dam._id,
        declaredTypes: litter.declaredTypes,
        dateOfBirth: litter.dateOfBirth,
        additionalInfo: litter.additionalInfo,
	      pendingRegistration: ! getRegAdmin(user, litter.registry),
      };
    });

    // Extract primaryImage file name
    let flattenedAttachments = [];
    let imageAttachments = [];

    if (litter.attachments) {
      flattenedAttachments = Object.values(litter.attachments).flat();
      // Extract all image attachments
      imageAttachments = flattenedAttachments.filter(
        (attachment) => attachment.get("category") === "profileImage");
      flattenedAttachments = flattenedAttachments.filter(
        (attachment) => attachment.get("category") !== "profileImage");

      for (const imageAttachment of imageAttachments) {
        const index = imageAttachment.get("index");
        const fileName = getImageFileName(imageAttachment);
        mappedPuppies[index].primaryImage = fileName;
        imageAttachment.set("fileName", fileName);
        /*
        await addProfilePictureById(
          puppyIds[imageAttachment.get("index")],
          imageAttachment,
          { accessToken }
        ).then((res) => {
          if (res.error) {
            throw new Error("Failed to upload profile picture: " + res.error);
          }
        }); */
      }
    }

    // Create puppies as registrable dogs with pending registration
    for (const puppy of mappedPuppies) {
      const newpuppy = await createDog(puppy, { accessToken });
      puppyIds.push(newpuppy._id);

      if (regAdmin) {
        	const regNum = await registerDog(newpuppy._id, litter.registry.prefix, {
          accessToken,
	    });
	    if (regNum === null || regNum === undefined) {
          throw new Error(
            "Failed to create registration number for puppy in database."
          );
	    }
      }
    }

    // Map litter into correct form
    // Do not send _id
    const { _id, ...transformedLitter } = {
      name: litter.name,
      puppies: puppyIds,
      sire: litter.sire._id,
      dam: litter.dam._id,
      declaredTypes: litter.declaredTypes,
      dateOfBirth: litter.dateOfBirth,
      breeder: breeder,
      organizations: [organizationWithReg],
      pending: ! regAdmin
    };

    // Create litter
    const newLitter = regAdmin ?
	  await createLitter(transformedLitter, { accessToken }) :
	  await createPendingLitter(transformedLitter, { accessToken });

    if (newLitter === null || newLitter._id === undefined || newLitter._id == null) {
      throw new Error("Failed to create new litter in database");
    }

    // Upload attachments
    for (const file of flattenedAttachments) {
      file.set(`parentDocType`, `litter`);
      file.set(`parentDoc`, newLitter._id);
      const newAttach = await addAttachment(file, { accessToken });
    }

    // Upload image attachments
    for (const imageAttachment of imageAttachments) {
      imageAttachment.set(`parentDocType`, `dog`);
      imageAttachment.set(`parentDoc`, puppyIds[imageAttachment.get("index")]);
      const newAttach = await addAttachment(imageAttachment, { accessToken });
    }
      /* Do this once this route works (when permissions are handled better)
      if (newAttach === null || newAttach._id === undefined || newAttach._id == null) {
        throw new Error("Failed to create new attachment in database");
      }
      if (file.get("category") === "profileImage" && file.get("primary") === "true") {
        await updatePrimaryImageById(
          newLitter._id,
          newAttach.fileName,
          { accessToken }
        );
      } */

    // Create statistics
    await createStatistic(litter.stats, { accessToken });

    const verifications = [];

    /* FIXME: do we want to do verifications? We can't email currently.
    // Create verification objects based on needed verifications
    if (litter.verificationNeeded && litter.verificationNeeded.sire === "email") {
      const verification = {
        operation: "verifyLitterSire",
        parentDoc: newLitter._id,
        parentDocType: "templitter",
      };
      verifications.push(createVerification(verification, { accessToken }));
    }
    if (litter.verificationNeeded && litter.verificationNeeded.dam === "email") {
      const verification = {
        operation: "verifyLitterDam",
        parentDoc: newLitter._id,
        parentDocType: "templitter",
      };
      verifications.push(createVerification(verification, { accessToken }));
    }
    */
    const savedAttachments = await Promise.all(verifications);

    // Do we need to pay?
    const amount = getPaymentAmount(user, "litter", litter.registry);

    if (amount == 0) {
      // handle admin registration
      window.location.assign(
        "/litter-registration/" + newLitter._id + "/success"
      );
    } else {
      // Create checkout for payment and redirect to Stripe
      const order = await createCheckout({
        accessToken,
        parentDoc: newLitter._id,
        parentDocType: "litter",
        organizations: [litter.registry._id],
      });
      if (!order.url) throw Error(`Cannot redirect to Stripe`);
      window.location.assign(order.url);
    }
  } catch (error) {
    console.error("Error in constructObject.js: ", error);
    return error;
  }
};

export const sendDogRegDataToApi = async (data, user, accessToken) => {
  try {
    const dog = structuredClone(data);
    const owner = getOwner (user, dog);
    const attachments = data.attachments;

    const organizationWithReg = {
      organization: dog.registry._id,
      regNumber: null,
      regClass: `reg.classes.full`,
      regType: `reg.types.individual`,
      certificates: [],
    };

    // Map input params to schema names
    dog.regType = "reg.types.individual";
    dog.regClass = "reg.classes.full";
    dog.owners = [owner];
    dog.organizations = [organizationWithReg];
    dog.countryOfOrigin = dog.countryOfOrigin.code;
    dog.sire = dog.sire?._id;
    dog.dam = dog.dam?._id;
    /* UNCOMMENT when coat stuff is updated */
    /* dog.markings.unshift(dog.color);
    dog.coatqualities.unshift(dog.coattype);
    dog.color = dog.markings;
    dog.coat = dog.coatqualities; */

    // Pending, unlesswe have admin authorization
    dog.pendingRegistration = ! getRegAdmin(user, dog.registry);

    // Do we need to pay?
    const amount = getPaymentAmount(user, "dog", dog.registry);

    if (amount == null) {
      throw new Error(
        "Could not find payment information for " +
          dog.registry.name +
          "."
      );
    }

    // TODO: don't create dog if there are problems
    const newDog = await createDog(dog, { accessToken });

    if (newDog === null || newDog._id === undefined || newDog._id == null) {
      throw new Error("Failed to create new dog in database");
    }

    if (!dog.pendingRegistration) {
      const regNum = await registerDog(newDog._id, dog.registry.prefix, {
        accessToken,
      });
      if (regNum === null || regNum === undefined) {
        throw new Error(
          "Failed to create registration number for dog in database."
        );
      }
    }

    if (amount == 0) {
      // handle admin/free registration
      window.location.assign("/dog-registration/" + newDog._id + "/success");
    } else {
      // Create checkout for payment and redirect to Stripe
      const order = await createCheckout({
        accessToken,
        parentDoc: newDog._id,
        parentDocType: "dog",
        organizations: [dog.registry._id],
      });
      if (!order.url) throw Error(`Cannot redirect to Stripe`);
      window.location.assign(order.url);
    }
  } catch (error) {
    console.error("Error in constructObject.js: ", error);
    // FIXME possible we have created a dog and failed to register it
    // We should investigate and clean up
    return error;
  }
};

export const sendTreedogAdditionDataToApi = async (
  treedog,
  user,
  accessToken
) => {
  try {
    if (treedog.countryOfOrigin) {
      treedog.countryOfOrigin = treedog.countryOfOrigin.code;
    }
    if (treedog.sire) {
      treedog.sire = treedog.sire._id;
    }
    if (treedog.dam) {
      treedog.dam = treedog.dam._id;
    }
    if (treedog.markings) {
      treedog.markings.unshift(treedog.color);
    }
    if (treedog.coatqualities) {
      treedog.coatqualities.unshift(treedog.coattype);
    }
    treedog.color = treedog.markings;
    treedog.coat = treedog.coatqualities;

    // Organizations
    const organizationWithReg = {
      organization: treedog.registry._id,
      regNumber: null,
      regClass: `reg.classes.tree`,
      regType: `reg.types.individual`,
    };

    treedog.organizations = [organizationWithReg];

    const newTreedog = await createNewTreedog(treedog, { accessToken });

    // Create attachments
    if (treedog.attachments) {
      // Flatten array of arrays to array of Formdatas
      const flattenedAttachments = Object.values(treedog.attachments).flat();
      for (const file of flattenedAttachments) {
        file.set(`parentDocType`, `dog`);
        file.set(`parentDoc`, newTreedog._id);
        await addAttachment(file, { accessToken });
      }
    }

    window.location.assign(
      "/admin/treedog-addition/" + newTreedog._id + "/success"
    );
  } catch (error) {
    console.error("Error in constructObject.js: ", error);
    return error;
  }
};
