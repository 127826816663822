import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";
import {
  attachmentsState,
  filepathsState,
  statsState,
} from "recoil/registrationStates";
import { Controller, useFormContext } from "react-hook-form";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Divider,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  Stack,
  TableContainer,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

// Local
import { UserContext } from "App";
import {
  countFemales,
  countMales,
  getKruValueLabel,
  getPaymentAmount,
} from "constants/helpers";


export default function TreedogSummary() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const methods = useFormContext();
  const { control, watch } = methods;
  const formData = watch();
  const checkboxes = formData.checkboxes;

  const user = useRecoilValue(userState);

  const [attachments, setAttachments] = useRecoilState(attachmentsState);

  const pedCertAttach = attachments.pedigreeCert &&
	attachments.pedigreeCert.length > 0;
  const miscAttach = attachments.misc && attachments.misc.length > 0;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("summary")}: {formData.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{formData.registry?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          href={formData.registry?.url}
          target="_blank"
          endIcon={<Launch />}
        >
          {t("registryGuidelines")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("Sire") + " & " + t("Dam")}</Typography>
        </Divider>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("sire")}</Typography>
            <Typography variant="body1">
              {formData.sire?.name || t("noData")} {formData.sire?.regNumber}
              <br />
              {t("DOB")} {dayjs(formData.sire?.dateOfBirth).format("MM.DD.YYYY")}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("dam")}</Typography>
            <Typography variant="body1">
              {formData.dam?.name || t("noData")} {formData.dam?.regNumber}
              {t("DOB")} {dayjs(formData.dam?.dateOfBirth).format("MM.DD.YYYY")}
            </Typography>
            <br />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Treedog information")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("dob")}</TableCell>
                <TableCell>{t("countryOfOrigin")}</TableCell>
                <TableCell>{t("microchipNumber")}</TableCell>
                <TableCell>{t("color")}</TableCell>
                <TableCell>{t("markings")}</TableCell>
                <TableCell>{t("coatType")}</TableCell>
                <TableCell>{t("coatQualities")}</TableCell>
                <TableCell>{t("height")}</TableCell>
                <TableCell>{t("weight")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {dayjs(formData.dateOfBirth).format("MM.DD.YYYY") ||
                    t("notProvided")}
                </TableCell>
                <TableCell>
                  {formData.countryOfOrigin?.[language] || "-"}
                </TableCell>
                <TableCell>
                  {formData.microchipNumber}
                </TableCell>
                <TableCell>
                  {getKruValueLabel(formData.color, language) || "-"}
                </TableCell>
                <TableCell>
		  {(formData.markings &&
                    formData.markings.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                   "-"}
                </TableCell>
                <TableCell>
                  {getKruValueLabel(formData.coattype, language) || "-"}
                </TableCell>
                <TableCell>
		  {(formData.coatqualities &&
                    formData.coatqualities.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                   "-"}
                </TableCell>
                <TableCell>
                  {formData.height || "-"}
                </TableCell>
                <TableCell>
                  {formData.weight || "-"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("attachments")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            {pedCertAttach || miscAttach ? (
              <TableBody>
                {pedCertAttach && attachments.pedigreeCert.length > 0 && (
                  <TableRow>
                    <TableCell>{t("Pedigree")}</TableCell>
                    <TableCell>
                      {attachments.pedigreeCert.map((e, i) => (
                        <p key={i}>{e.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {miscAttach && attachments.misc.length > 0 && (
                  <TableRow>
                    <TableCell>{t("otherAttachments")}</TableCell>
                    <TableCell>
                      {attachments.misc.map((e, i) => (
                        <p key={i}>{e.get(`attachment`).name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>{t("noAttachments")}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      
      </Grid>
  );
}
