import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { getKruValueLabel } from "constants/helpers";

// Icons
import LaunchIcon from "@mui/icons-material/Launch";
// MUI
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { countries } from "constants/countries";

export default function BasicInformation({ dog, organization }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const error = t("noData");

  const [country, setCountry] = useState(
    dog.countryOfOrigin
      ? countries.find((c) => c.code === dog.countryOfOrigin.toUpperCase())[
          lang
        ]
      : null
  );

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell variant="head">{t("name")}</TableCell>
              <TableCell>{dog.name || error}</TableCell>
              <TableCell variant="head">{t("color")}</TableCell>

              <TableCell>
                {dog?.color?.map((value, i) => {
                  return (
                    getKruValueLabel(value, lang) +
                    (dog.color.length - 1 === i ? "" : ", ")
                  );
                }) || error}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("dob")}</TableCell>
              <TableCell>
                {dayjs(dog?.dateOfBirth).format("MM.DD.YYYY") || error}
              </TableCell>
              <TableCell variant="head">{t("coat")}</TableCell>
              <TableCell>
                {dog?.coat?.map((value, i) => {
                  return (
                    getKruValueLabel(value, lang) +
                    (dog.coat.length - 1 === i ? "" : ", ")
                  );
                }) || error}
              </TableCell>
            </TableRow>

            <TableRow hover>
              <TableCell variant="head">{t("countryOfOrigin")}</TableCell>
              <TableCell>{country || error}</TableCell>
              <TableCell variant="head">{t("height")}</TableCell>
              <TableCell>{dog.height ? dog.height + " cm" : error}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("sex")}</TableCell>
              <TableCell>{t(dog.sex) || error}</TableCell>
              <TableCell variant="head">{t("weight")}</TableCell>
              <TableCell>{dog.weight ? dog.weight + " kg" : error}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("breeder")}</TableCell>
              <TableCell colSpan={2}>
                {dog?.breeder?.name}
                {/* {breederBadge} */}
              </TableCell>
              <TableCell>
                {dog?.breeder ? (
                  <Button
                    href={`/${organization.prefix}/breeder/${dog.breeder.breederId}`}
                    endIcon={<LaunchIcon />}
                    size="small"
                    sx={{ float: "right" }}
                  >
                    {t("breedersProfile")}
                  </Button>
                ) : (
                  " "
                )}
              </TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell variant="head">{t("Owner(s)")}</TableCell>
              <TableCell colSpan={2}>
                {dog?.owners?.map(owner => owner.name ? owner.name : `Not disclosed (${owner.breederId})`).join(', ')}
              </TableCell>
  
            </TableRow>

            {dog?.isDeceased && (
              <>
                <TableRow hover>
                  <TableCell variant="head">{t("dateOfDeath")}</TableCell>
                  <TableCell>
                    {dayjs(dog?.dateOfDeath).format("MM.DD.YYYY")}
                  </TableCell>
                  <TableCell variant="head">{t("causeOfDeath")}</TableCell>
                  <TableCell>{dog?.causeOfDeath}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
