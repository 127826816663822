import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";
import { adminAllUsersState } from "recoil/adminStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Alert,
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";

// Local
import { countries } from "constants/countries";
import { compareRole, getUserManagerPlusOrg } from "constants/helpers";

export default function DogOwner() {
  const user = useRecoilValue(userState);
  const [formData, setFormData] = useRecoilState(dogRegistrationState);

  // if site level manager/admin, use management UI
  // if org level manager/admin in specified registry, use management UI
  if (compareRole(user.role, "manager")) {
    return <DogOwnerManager />;
  }

  if (formData.registry === undefined) {
    return <DogOwnerBasic />;
  }

  if (getUserManagerPlusOrg(user, formData.registry)) {
    return <DogOwnerManager />;
  }

  return <DogOwnerBasic />;
}

export function DogOwnerManager() {
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const users = useRecoilValue(adminAllUsersState);
  const methods = useFormContext();
  const [formData, setFormData] = useRecoilState(dogRegistrationState);

  const {
    control,
    formState: { errors, isSubmitting, isDirty },
  } = methods;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">Owner</Typography>
          </Divider>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="owner"
            rules={{
              required: "Required",
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                fullWidth
                required
                options={users}
                value={formData.owner || null}
                getOptionLabel={(option) => `${option.name} (${option.email})`}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {" ("}
                    {option.email}
                    {") "}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option._id == value._id
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Owner"
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["owner"]: data
                  });
                }}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.owner}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">{t("Owner preview")}</Typography>
          <List>
            {formData && formData.owner && (
              <ListItem secondaryAction={formData.owner.regNumber}>
                <ListItemText
                  primary={`${formData.owner.name}`}
                  secondary={
                    `${formData.owner.email}` /* (FDD ID: ${formData.owner._id}) */
                  }
                />
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  );
}

export function DogOwnerBasic() {
  const { t, i18n } = useTranslation();
  const user = useRecoilValue(userState);
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const { checkboxes } = watch();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("ownerInformation")}</Typography>
          </Divider>
          <Typography variant="body">
            <br />
            {t("ownerInfoText2")} <b>{t("ownerInfoText3")}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("ownersFullName")}
            value={user?.name || ""}
            error={user?.name === null || user?.name === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("ownerId")}
            value={user?.ownerId || t("noOwnerId")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("ownerPrefix")}
            value={user?.breedingPrefix || t("noOwnerPrefix")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("address")}
            value={user?.address || t("noData")}
            error={user?.address === undefined || user?.address === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("postalCode")}
            value={user?.postalCode || t("noData")}
            error={user?.postalCode === undefined || user?.postalCode === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("city")}
            value={user?.city || t("noData")}
            error={user?.city === undefined || user?.city === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("country")}
            value={
              user?.country
                ? countries.find(
                    (c) => c.code === user?.country?.toUpperCase()
                  )[i18n.language]
                : t("noData")
            }
            error={user?.country === undefined || user?.country === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("phoneNumber")}
            value={user?.phone || t("noData")}
            error={user?.phone === undefined || user?.phone === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            required
            size="small"
            label={t("email")}
            value={user?.email || t("noData")}
            error={user?.email === undefined || user?.email === ""}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("permissionToPublishName")}
            value={user?.permissions?.name ? t("yes") : t("no")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            size="small"
            label={t("permissionToPublishCity")}
            value={user?.permissions?.city ? t("yes") : t("no")}
            disabled
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ float: "right" }}>
            <FormControl error={errors.checkboxes?.ownerVerified && true}>
              <FormControlLabel
                control={
                  <Controller
                    name="checkboxes.ownerVerified"
                    control={control}
                    defaultValue={checkboxes?.ownerVerified || false}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label={t("ownerInfoConfirm")}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {errors.checkboxes?.ownerVerified && (
            <Alert severity="error">{t("ownerInfoConfirmError")}</Alert>
          )}
        </Grid>
      </Grid>
    </>
  );
}
