import React from "react";
import { Link } from "react-router-dom";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { kruValuesState, userState } from "recoil/globalStates";
import {
  dogRegistrationState
} from "recoil/registrationStates";

// MUI
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
import { Launch } from "@mui/icons-material";

import {
  createHandleInputChange,
  getNameByLang,
  getPaymentAmount,
} from "constants/helpers";

export default function DogSummary() {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const kruValues = useRecoilValue(kruValuesState);
  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const { attachments } = formData;

  const handleInputChange = createHandleInputChange(setFormData);

  const methods = useFormContext();
  const { control } = methods;

  const user = useRecoilValue(userState);
  let owner = formData.owner;
  if (owner === undefined || owner === null) {
    owner = user;
  }

  // Set price. Note it will be null when the form refreshes
  // just after submission.
  let price = getPaymentAmount(user, "dog", formData.registry);
  price = price == null ? 0 : price.toFixed(2);

  const pedCertAttach =
    attachments.pedigreeCert && attachments.pedigreeCert.some(item => Object.keys(item).length > 0);
  const miscAttach = attachments.misc && attachments.misc.some(item => Object.keys(item).length > 0);
  const imageAttach = attachments.profileImage && attachments.profileImage.some(item => Object.keys(item).length > 0);;

  const declaredTypes = () => {
    const breeds = kruValues.filter((i) => i.category === "ancestry" && i._id.startsWith("breeds"));
    const typeName = (type) => {
      return getNameByLang(type, breeds, language);
    };

    if (formData.declaredBreed) {
      return typeName(formData.declaredBreed);
    } else if (formData.declaredMix) {
      return typeName(formData.declaredMix);
    } else if (formData.declaredType1 && formData.declaredType2) {
      return `${typeName(formData.declaredType1)} / ${typeName(formData.declaredType2)} mix`;
    } else if (formData.declaredType1) {
      return `${typeName(formData.declaredType1)} mix`;
    }
    return "-";
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2">{formData.name}</Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("owner")}</Typography>
        </Divider>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              {owner?.name}
              <br />
              {owner?.address || t("noData")} {owner?.postalCode || " "}{" "}
              {owner?.city || " "} <br />
              {owner?.phone || t("noData")}
              <br />
              {owner?.email || t("noData")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {" "}
            <Typography variant="body1">
              {t("ownerPrefix")}: {owner?.breedingPrefix || t("noData")}
              <br />
              {t("permissionToPublishName")}:{" "}
              {owner?.permissions?.name ? t("yes") : t("no")}
              <br />
              {t("permissionToPublishCity")}:{" "}
              {owner?.permissions?.city ? t("yes") : t("no")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("registry")}</Typography>
        </Divider>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{formData.registry?.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          href={formData.registry?.url}
          target="_blank"
          endIcon={<Launch />}
        >
          {t("registryGuidelines")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left">
          <Typography variant="h3">{t("Sire") + " & " + t("Dam")}</Typography>
        </Divider>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("sire")}</Typography>
            <Typography variant="body1">
              {formData.sire?.name || t("noData")} {formData.sire?.regNumber}
              <br />
              {formData.sire && t("DOB") + " " +
               dayjs(formData.sire?.dateOfBirth).format("MM.DD.YYYY")}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h3">{t("dam")}</Typography>
            <Typography variant="body1">
              {formData.dam?.name || t("noData")} {formData.dam?.regNumber}
              {formData.dam && t("DOB") + " " +
               dayjs(formData.dam?.dateOfBirth).format("MM.DD.YYYY")}
            </Typography>
            <br />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Dog information")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("dob")}</TableCell>
                <TableCell>{t("countryOfOrigin")}</TableCell>
                <TableCell>{t("microchipNumber")}</TableCell>
                {/*
                <TableCell>{t("color")}</TableCell>
                <TableCell>{t("Modifiers")}</TableCell>
                <TableCell>{t("markings")}</TableCell>
                <TableCell>{t("coatType")}</TableCell>
                <TableCell>{t("coatQualities")}</TableCell> */ }
                <TableCell>{t("Breed")}</TableCell>
                <TableCell>{t("height")}</TableCell>
                <TableCell>{t("weight")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {dayjs(formData.dateOfBirth).format("MM.DD.YYYY") ||
                    t("notProvided")}
                </TableCell>
                <TableCell>
                  {formData.countryOfOrigin?.[language] || "-"}
                </TableCell>
                <TableCell>{formData.microchipNumber}</TableCell>
               { /* <TableCell>
                  {getKruValueLabel(formData.color, language) || "-"}
                </TableCell>
                <TableCell>
                  {(formData.modifiers &&
                    formData.modifiers.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {(formData.markings &&
                    formData.markings.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell>
                <TableCell>
                  {getKruValueLabel(formData.coattype, language) || "-"}
                </TableCell>
                <TableCell>
                  {(formData.coatqualities &&
                    formData.coatqualities.map((v, i) => {
                      return (
                        <div key={i}>
                          {getKruValueLabel(v, language)}
                          <br />
                        </div>
                      );
                    })) ||
                    "-"}
                </TableCell> */ }
                <TableCell>
                  {declaredTypes()}
                </TableCell>
                <TableCell>{formData.height || "-"}</TableCell>
                <TableCell>{formData.weight || "-"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {formData.embarkURL && (
      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Test results")}</Typography>
        </Divider>
        <Typography variant="body1">{`Embark: ${formData.embarkURL}`}</Typography>
      </Grid>
      )}

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("attachments")}</Typography>
        </Divider>
        <TableContainer>
          <Table>
            {pedCertAttach || miscAttach || imageAttach ? (
              <TableBody>
                {pedCertAttach && (
                  <TableRow>
                    <TableCell>{t("Pedigree")}</TableCell>
                    <TableCell>
                      {attachments.pedigreeCert.map((a, i) => (
                        <p key={i}>{a.name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {imageAttach > 0 && (
                  <TableRow>
                    <TableCell>{t("Images")}</TableCell>
                    <TableCell>
                      {attachments.profileImage.map((a, i) => (
                        <p key={i}>{a.name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
                {miscAttach && (
                  <TableRow>
                    <TableCell>{t("otherAttachments")}</TableCell>
                    <TableCell>
                      {attachments.misc.map((a, i) => (
                        <p key={i}>{a.name}</p>
                      )) || "-"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell>{t("noAttachments")}</TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="left" sx={{ my: 3 }}>
          <Typography variant="h3">{t("Notes")}</Typography>
        </Divider>
        <Typography variant="body1">{formData.additionalInfo || t("noData")}</Typography>
      </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h3">{t("termsAndPayment")}</Typography>
            </Divider>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Description")}</TableCell>
                    <TableCell>{t("Price")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{t("dogRegistration")}</TableCell>
                    <TableCell>{price} USD</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      <Typography variant="caption">
                        {t("anyDiscountsCalculatedAtCheckout")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <Stack spacing={2} sx={{ mt: 3 }}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="dataVerified"
                      control={control}
                      defaultValue={formData.dataVerified}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.dataVerified}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                        />
                      )}
                    />
                  }
                  label={owner === user ? t("ownerConfirmation") : t("I declare that the information I have given is true.")}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="orgAgreement"
                      control={control}
                      defaultValue={formData.orgAgreement}
                      render={({ field }) => (
                        <Checkbox {...field}
                          checked={field.value}
                          value={formData.orgAgreement}
                          onChange={(e) => handleInputChange(e, field)}
                          />
                      )}
                    />
                  }
                  label={t("termsConfirmation", {
                    registryName: formData.registry?.name,
                  })}
                />
              </FormControl>
              <FormControl>
                <FormControlLabel
                  control={
                    <Controller
                      name="platformAgreement"
                      control={control}
                      defaultValue={formData.platformAgreement}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          value={formData.platformAgreement}
                          onChange={(e) => handleInputChange(e, field)}
                          checked={field.value}
                          />
                      )}
                    />
                  }
                  label={
                    <>
                      {t("I have read and agreed to the ")}
                      <Link to="/terms" target="_blank" rel="noopener noreferrer">{t("FunctionalDogData terms of use.")}</Link>
                    </>
                  }                />
              </FormControl>
            </Stack>
          </Grid>
    </Grid>
  );
}
