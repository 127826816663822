import React, { useEffect, useState } from "react";
import {
  Controller,
  useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import {
  constSelector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  kruValuesState,
  loadingState,
  snackBarState,
} from "recoil/globalStates";
import {
  adminAllOrgsState,
  adminAllUsersState,
  adminDogsBySexState,
  adminGetBreederByIdState,
  adminGetOwnersByIdState,
  adminTreedogsBySexState,
} from "recoil/adminStates";

// MUI
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Launch } from "@mui/icons-material";

// Local
import { countries } from "constants/countries";
import { getDogType } from "constants/helpers";

// This form is also used for pending dogs and tree dogs
function AdminDogInformation ({
  dog,
  formMethods
}) {
  const [initialDog, setInitialDog] = useState(dog);

  const { t, i18n } = useTranslation();

  const kruValues = useRecoilValue(kruValuesState);
  const users = useRecoilValue(adminAllUsersState);
  const setLoading = useSetRecoilState(loadingState);

  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loadingOverlayState, setLoadingOverlayState] =
    useRecoilState(loadingState);

  const theme = useTheme();

  // What type of dog is this: registered, pending, or tree?
  const dogType = getDogType(dog);

  // fetch initial breeder data with breeder's id
  const [initBreeder, setInitBreeder] = useState(
    useRecoilValue(
      dog?.breeder ? adminGetBreederByIdState(dog.breeder) : constSelector(null)
    )
  );

  // fetch initial owners data with owners' ids
  const [initOwners, setInitOwners] = useState(
    useRecoilValue(
      dog?.owners ? adminGetOwnersByIdState(dog.owners) : constSelector([])
    )
  );

  const [hiddenOwners, sethiddenOwners] = useState(null);

  const {
    control,
    setError,
    clearErrors,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = formMethods;

  const {
    append: appendRegOther,
    fields: regOtherFields,
    remove: removeRegOther,
  } = useFieldArray({ control, name: "regOther" });

  const {
    append: appendCerts,
    fields: certFields,
    remove: removeCerts,
  } = useFieldArray({ control, name: "certificates" });

  const formdata = watch();
  const isDeceased = formdata.isDeceased;
  const regType = formdata.regType;
  const regOther = formdata.regOther;
  const owners = formdata.owners;
  const breeder = formdata.breeder;
  const orgAndMore = dog?.organizations[0];
 
  // set the regOther array from the initial dog since useFieldArray doesnt allow
  // setting a default and useForm defaults dont allow variables as defaults
    useEffect(() => {
    // Count how many hidden owners the dog has if any
    if (owners) {
      let hiddenOwners = 0;
      owners.forEach((owner) => {
        if (owner === "hidden") hiddenOwners++;
      });
      sethiddenOwners(hiddenOwners);
    }
  }, [dog]);
  
  const femaleDogs = useRecoilValue(adminDogsBySexState("female")).
    concat(useRecoilValue(adminTreedogsBySexState("female")));
  const maleDogs = useRecoilValue(adminDogsBySexState("male")).
    concat(useRecoilValue(adminTreedogsBySexState("male")));
  
  const organizations = useRecoilValue(adminAllOrgsState);

  const [selectedCountry, setSelectedCountry] = useState(
    dog?.countryOfOrigin
      ? countries.find((c) => c.code === dog.countryOfOrigin.toUpperCase())
      : countries.find((c) => c.code === i18n.language.toUpperCase())
  );

  const [selectedSire, setSelectedSire] = useState(dog?.sire || null);
  const [selectedDam, setSelectedDam] = useState(dog?.dam || null);


  return (
        <Grid container direction="row" spacing={3} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialDog?.name || ""}
              rules={{
                required: t("Required"),
                minLength: 1,
                maxLength: { value: 100, message: "Max 100 characters" },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("Dog's name")}
                  error={errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
	  {dogType === "treedog" ?
	   <Controller
	     name="microchipNumber"
	     control={control}
	     defaultValue={initialDog?.microchipNumber || ""}
	     render={({ field }) => (
	       <TextField
		 {...field}
		 fullWidth
		 label="Microchip number"
	       />
	     )}
	   /> :
	   <Controller
	     name="microchipNumber"
	     control={control}
	     defaultValue={initialDog?.microchipNumber || ""}
	     rules={{
	       required: "Required",
	       minLength: 1,
	     }}
	     render={({ field }) => (
	       <TextField
		 {...field}
		 fullWidth
		 required
		 label="Microchip number"
		 error={errors?.microchipNumber}
		 helperText={
		   errors?.microchipNumber && errors?.microchipNumber?.message
		 }
	       />
	     )}
	   />
	  }
          </Grid>
	  {dogType === "treedog" ?
          <Grid item xs={12} md={6}>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={initialDog?.dateOfBirth || null}
              render={({ field: { ref, name, ...field } }) => (
                <DatePicker
                  {...field}
                  inputRef={ref}
                  label="Date of birth"
                  onChange={(e) => field.onChange(dayjs(e).toISOString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      name={name}
                    />
                  )}
                  disableFuture
                />
              )}
            />
          </Grid> :
	   <Grid item xs={12} md={6}>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue={initialDog?.dateOfBirth || null}
              rules={{
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <DatePicker
                  {...field}
                  required
                  inputRef={ref}
                  label="Date of birth"
                  onChange={(e) => field.onChange(dayjs(e).toISOString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      name={name}
                      error={errors?.dateOfBirth && true}
                      helperText={errors && errors?.dateOfBirth?.message}
                    />
                  )}
                  disableFuture
                />
              )}
            />
          </Grid>
	  }

           <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Sex *</InputLabel>
              <Controller
                name="sex"
                control={control}
                defaultValue={initialDog?.sex || ""}
                rules={{
                  minLength: 1,
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="Sex">
                    <MenuItem value="female">female</MenuItem>
                    <MenuItem value="male">male</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText error={errors?.sex && true}>
                {errors?.sex?.message}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="countryOfOrigin"
              rules={{
                required: "Required",
              }}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  required
                  label="Country of origin"
                  options={countries}
                  isOptionEqualToValue={(option, value) =>
                    option.code == value.code
                  }
                  getOptionLabel={(option) => option["en"]}
                  renderOption={(props, option) => (
                    <li {...props} key={option.code}>
                      {option["en"]} {option.code}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Country of origin"
                      error={errors?.countryOfOrigin}
                      helperText={
                        errors?.countryOfOrigin &&
                        errors?.countryOfOrigin?.message
                      }
                    />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedCountry || null}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Controller
                    name="isDeceased"
                    control={control}
                    defaultValue={initialDog?.isDeceased || false}
                    render={({ field }) => (
                      <Checkbox {...field} checked={field.value} />
                    )}
                  />
                }
                label="Mark as deceased"
              />
            </FormControl>

            {isDeceased && (
              <>
                <Controller
                  name="dateOfDeath"
                  control={control}
                  defaultValue={initialDog?.dateOfDeath || null}
                  render={({ field: { ref, name, ...field } }) => (
                    <DatePicker
                      {...field}
                      inputRef={ref}
                      label="Date of death"
                      onChange={(e) => field.onChange(dayjs(e).toISOString())}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  )}
                />
                <br />
                <br />
                <Controller
                  name="causeOfDeath"
                  control={control}
                  defaultValue={initialDog?.causeOfDeath || ""}
                  render={({ field }) => (
                    <TextField {...field} fullWidth label="Cause of death" />
                  )}
                />
              </>
            )}
          </Grid>

	    <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Owners</Typography>
            </Divider>
          </Grid>
          {hiddenOwners > 0 ? (
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  This dog has {hiddenOwners} owners outside of your
                  organization
                </AlertTitle>
                Managers can not edit owners if one or more of them are part of
                a different organization. Please contact the administrator for
                more information.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="owners"
                  render={({ field: { onChange, ...props } }) => (
                    <Autocomplete
                      multiple
                      options={users}
                      getOptionLabel={(option) =>
                        `${option?.name} ${option?.email ? " - " : ""} ${
                          option?.email || " "
                        }`
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.name} {" ("}
                          {option.email}
                          {") "}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option._id == value?._id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Owners" />
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...props}
                    />
                  )}
                  onChange={([, data]) => data}
                  defaultValue={initOwners || []}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Owners preview</Typography>
                <List>
                  {owners &&
                    owners?.map((owner, i) => {
                      return (
                        owner && (
                          <ListItem
                            key={owner._id}
                            secondaryAction={owner.breederId || ""}
                          >
                            <ListItemText
                              primary={`${owner.name}`}
                              secondary={`${owner?.email || ""} 
                            (${owner?._id || ""})`}
                            />
                          </ListItem>
                        )
                      );
                    })}
                </List>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Breeder</Typography>
            </Divider>
          </Grid>
          {breeder === "hidden" ? (
            <Grid item xs={12}>
              <Alert severity="info">
                <AlertTitle>
                  This dog has breeder that is outside of your organization
                </AlertTitle>
                Managers can not edit breeder information if the breeder is part
                of a different organization. Please contact the administrator
                for more information.
              </Alert>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Controller
                  name="breeder"
                  render={({ field: { onChange, ...props } }) => (
                    <Autocomplete
                      fullWidth
                      options={users}
                      getOptionLabel={(option) =>
                        `${option.name} (${option.email})`
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.name} {" ("}
                          {option.email}
                          {") "}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option._id == value._id
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Breeder" />
                      )}
                      onChange={(e, data) => onChange(data)}
                      {...props}
                    />
                  )}
                  onChange={([, data]) => data}
                  defaultValue={initBreeder}
                  control={control}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5">Breeder preview</Typography>

                <List>
                  {breeder && (
                    <ListItem secondaryAction={breeder?.regNumber}>
                      <ListItemText
                        primary={`${breeder?.name}`}
                        secondary={`${breeder?.email || ""} (${breeder?._id})`}
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">Family</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="sire"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  options={maleDogs}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.regNumber || ""}`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name} {option.regNumber || ""}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id == value._id
                  }
                  autoComplete
                  noOptionsText={"no data"}
                  renderInput={(params) => (
                    <TextField {...params} label="Sire" />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedSire || null}
              control={control}
            />
            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">{t("Sire preview")}</Typography>
              </ListItem>
              {formdata.sire && (
                <ListItem>
                  <ListItemButton
                    href={`/admin/dog/${formdata.sire._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.sire.name} ${
                        formdata.sire.regNumber || ""
                      }`}
                      secondary={`${formdata.sire._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="dam"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  options={femaleDogs}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.regNumber || ""}`
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name} {option.regNumber || ""}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option._id == value._id
                  }
                  autoComplete
                  noOptionsText={"no data"}
                  renderInput={(params) => (
                    <TextField {...params} label={t("Dam")} />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={selectedDam || null}
              control={control}
            />

            <List sx={{ mt: 2 }}>
              <ListItem>
                <Typography variant="h5">{t("Dam preview")}</Typography>
              </ListItem>
              {formdata.dam && (
                <ListItem>
                  <ListItemButton
                    href={`/admin/dog/${formdata.dam._id}`}
                    target="_blank"
                  >
                    <ListItemIcon>
                      <Launch />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${formdata.dam.name} ${
                        formdata.dam.regNumber || ""
                      }`}
                      secondary={`${formdata.dam._id}`}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Grid>
          </Grid>
  );
}

export default AdminDogInformation;
