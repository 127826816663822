import React from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

// Recoil
import { useRecoilValue } from "recoil";
import {
  accessTokenState,
  kruValuesState,
  userState,
} from "recoil/globalStates";

// MUI
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Local
import { checkIfMicrochipExists } from "api/privateRoutes";
import { countries } from "constants/countries";

export default function TreedogInformation({ treedog, setTreedog, formErrors }) {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const accessToken = useRecoilValue(accessTokenState);
  const user = useRecoilValue(userState);
  const kruValues = useRecoilValue(kruValuesState);

  const {
    control,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();

  const {
    name,
    microchipNumber,
    sex,
    dateOfBirth,
    countryOfOrigin,
    color,
    markings,
    coattype,
    coatqualities,
    height,
    weight
  } = watch();

  // TODO this is duplicated in litter registration (litterPuppies)
  // Consider moving to helpers - but have to deal with error handling
  const checkMicrochipNumber = (e) => {
    if (e.target.value && e.target.value !== "") {
      checkIfMicrochipExists(e.target.value, { accessToken }).then((response) => {
        if (response === true) {
          setError(e.target.name, {
            type: "custom",
            message: t("checkMicrochipNumber"),
          });
        } else {
          clearErrors(e.target.name);
        }
      });
      clearErrors(e.target.name);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Dog's information")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("formInfoText")}
            <b>{t("ownerInfoText3")}</b>
          </Typography>
        </Grid>

      <Grid item xs={5}>
      <Controller
        name="name"
        control={control}
        defaultValue={name || ""}
        rules={{
          required: t("required"),
        }}
        render={({ field }) => (
        <TextField
            {...field}
	  fullWidth
	  required
	  label={t("Name")}
	  error={errors?.name && true}
	  helperText={
            errors ? errors?.name?.message : t("May be an alias")
	  }
            />
	)}
      />
      </Grid>
    
      <Grid item>
        <Controller
          name="microchipNumber"
          control={control}
          defaultValue={microchipNumber || null}
          rules={{
            onBlur: (e) => checkMicrochipNumber(e),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              label={t("Microchip number")}
              error={errors?.microchipNumber && true}
              helperText={
                errors &&
                errors?.microchipNumber?.message
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={3}>
        <FormLabel>{t("sex")}</FormLabel>
        <Controller
          name="sex"
          control={control}
          defaultValue={sex || "male"}
          rules={{
            required: t("required"),
          }}
          render={({ field }) => (
            <RadioGroup {...field} row>
              <FormControlLabel
                value="male"
                control={<Radio />}
                label={
                  <Typography variant="body">
                    {t("male")}
                  </Typography>
                }
              />
              <FormControlLabel
                value="female"
                control={<Radio />}
                label={
                  <Typography variant="body">
                    {t("female")}
                  </Typography>
                }
              />
            </RadioGroup>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue={dateOfBirth || null}
            render={({ field: { ref, name, ...field } }) => (
              <DatePicker
                {...field}
                inputRef={ref}
                label={t("Date of birth")}
                onChange={(e) => field.onChange(dayjs(e).toISOString())}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name={name}
                    error={errors?.dateOfBirth && true}
                    helperText={errors && errors?.dateOfBirth?.message}
                  />
                )}
                disableFuture
              />
            )}
          />
      </Grid>
      
        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="countryOfOrigin"
            rules={{
              required: true,
            }}
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                options={countries}
                getOptionLabel={(option) =>
                  option[language] ? option[language] : ""
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {option[language]} {option.code}
                  </li>
                )}
                placeholder={t("Country of origin")}
                isOptionEqualToValue={(option, value) =>
                  option.code == value.code
                }
                renderInput={(params) => (
                  <TextField {...params} label={t("Country of origin")} />
                )}
                onChange={(e, data) => onChange(data)}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={
              countryOfOrigin ||
              countries.find((c) => c.code === user.country?.toUpperCase())
            }
            control={control}
          />
      </Grid>

    
        <Grid item xs={12} sm={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">{t("Coat")}</Typography>
          </Divider>
        </Grid>
           <Grid item xs={6}>
             <FormControl fullWidth>
               <InputLabel>{t("Main color")}</InputLabel>
               <Controller
                 name="color"
                 control={control}
                 defaultValue={color || ""}
                 render={({ field }) => (
                   <Select {...field} fullWidth label={t("mainColor")}>
                     {kruValues
                       .filter((i) => i.category === "coat.colors")
                       .map((color) => (
                         <MenuItem key={color._id} value={color._id}>
                           {color[language].name}
                         </MenuItem>
                       ))}
                   </Select>
                 )}
               />
             </FormControl>
           </Grid>
           <Grid item xs={6}>
             <FormControl fullWidth>
               <InputLabel>{t("markings")}</InputLabel>
               <Controller
                 name="markings"
                 control={control}
                 defaultValue={markings || []}
                 render={({ field }) => (
                   <Select
                     {...field}
                     fullWidth
                     multiple
                     label={t("markings")}
                   >
                     {kruValues
                       .filter((i) => i.category === "coat.markings")
                       .map((color) => (
                         <MenuItem key={color._id} value={color._id}>
                           {color[language].name}
                         </MenuItem>
                       ))}
                   </Select>
                 )}
               />
             </FormControl>
           </Grid>
           <Grid item xs={6}>
             <FormControl fullWidth>
               <InputLabel>{t("coatType")}</InputLabel>
               <Controller
                 name="coattype"
                 control={control}
                 defaultValue={coattype || ""}
                 render={({ field }) => (
                   <Select {...field} fullWidth label={t("coatType")}>
                     {kruValues
                       .filter((i) => i.category === "coat.types")
                       .map((color) => (
                         <MenuItem key={color._id} value={color._id}>
                           {color[language].name}
                         </MenuItem>
                       ))}
                   </Select>
                 )}
               />
             </FormControl>
           </Grid>
           <Grid item xs={6}>
             <FormControl fullWidth>
               <InputLabel>{t("coatQualities")}</InputLabel>
               <Controller
                 name="coatqualities"
                 control={control}
                 defaultValue={coatqualities || []}
                 render={({ field }) => (
                   <Select
                     {...field}
                     fullWidth
                     multiple
                     label={t("Coat qualities")}
                   >
                     {kruValues
                       .filter((i) => i.category === "coat.qualities")
                       .map((color) => (
                         <MenuItem key={color._id} value={color._id}>
                           {color[language].name}
                         </MenuItem>
                       ))}
                   </Select>
                 )}
               />
             </FormControl>
           </Grid>

        <Grid item xs={12} sm={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">{t("Size")}</Typography>
          </Divider>
        </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Controller
            name="height"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={t("Height")}
		  InputProps={{
		    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
		  }}
              />
            )}
          />
        </FormControl>
      </Grid>

          <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <Controller
            name="weight"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                label={t("Weight")}
		  InputProps={{
		    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
		  }}
              />
            )}
          />
        </FormControl>
      </Grid>
      </Grid>
    </>
  );
}
